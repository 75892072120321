import style from "./homepage.module.css"
import { ContextState } from "../App";
import { useContext} from "react";
import HeroSection from "../heroSection/heroSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLessThan} from "@fortawesome/free-solid-svg-icons";
import AboutMeSection from "../aboutMeSection/aboutMeSection";
import ServiceSection from "../servicesSection/servicesSection";
import FooterSection from "../footer/footer";
import { motion, useScroll } from "framer-motion";
import { Helmet } from "react-helmet-async";



function Homepage(){
  const { width, height } = useContext(ContextState); 
  // const [arrowHeight, setArrowHeight] = useState(0);
  const { scrollYProgress } = useScroll();

  // useEffect(() => {
  //   function arrowHeightChange() {
  //     setArrowHeight(window.scrollY);
  //   }

  //   window.addEventListener("scroll", arrowHeightChange);

  //   arrowHeightChange();
  // }, [arrowHeight]);



  return (
    <div
      className={style.homepageContainer}
      style={{ width: `${width}px`, minHeight: `${height}px` }}
    >
      <Helmet>
        <title>Hvll Digital</title>
        <meta
          name="description"
          content="Hvll Digital, creative web development agent based in London that crafts beautiful and original websites for brands who refuse to blend in."
        ></meta>
        <link rel="canonical" href="/"></link>
      </Helmet>

      <div className={style.arrow}>
        <div
          className={`${style.arrowIconHolder}`}
          style={
            {
              //   maxHeight: `${20 + arrowHeight}px`,
              // scaleY: scrollYProgress,
            }
          }
        >
          <motion.div
            className={`${style.arrowBody} primaryClr`}
            style={{
              width: `3px`,
              scaleY: scrollYProgress,
            }}
          ></motion.div>
          <FontAwesomeIcon
            icon={faLessThan}
            style={{ transform: `rotate(${-90}deg)`, fontSize: `24px` }}
            className={`fontPrimaryClr`}
          />
        </div>
      </div>

      <HeroSection />
      <AboutMeSection />
      <ServiceSection />
      <FooterSection />
    </div>
  );
}

export default Homepage;