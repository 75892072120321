// import styles
import "./share_resources/css/brandColours.scss";

// navbar
import "./share_resources/fonts/brand-fonts.scss";
import TNCBar from "./TnCBar/tNc";

// TnC navbar
import "./TnCBar/tNCBar.scss";
import React, { useState, useEffect } from "react";

// import react router dom
import { Route, Routes } from "react-router-dom";
import Homepage from "./homepage/homepage";
import CreativeWebDesign from "./services/creativeWebDesign/creativeWebDesign";
import PrivacyPolicy from "./Legal/privacyPolicy/privacy";
import TermsNCondition from "./Legal/TermsCondition/TermsNCondition";
import EmailDevelopment from "./services/EmailDevelopment/emailDevelopment";
import WebDevelopment from "./services/WebDevelopment/webDevelopment";
import WordpressDevelopment from "./services/WordPressDevelopment/wordPressDevelopment";
import { Helmet } from "react-helmet-async";

export const ContextState = React.createContext();

function App() {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  // buttons
  const [isEnter, setIsEnter] = useState(true);
  // acceptTNC
  const [accept, setAccept] = useState(true);
  // navbaractive
  const [navbarActive, setNavbarActive] = useState(true);
  // to send the info is enter and set
  let enter = [isEnter, setIsEnter];
  let tnCAccept = [accept, setAccept];

  // navbarActive
  let navbarActiveVar = [navbarActive, setNavbarActive];
  // Dynamic Width
  useEffect(() => {
    function widthChange() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", widthChange);

    widthChange();
  }, [width]);

  // Dynamic Height
  useEffect(() => {
    function heightChange() {
      setHeight(window.innerHeight);
    }

    window.addEventListener("resize", heightChange);

    heightChange();
  }, [height]);

  return (
    <ContextState.Provider
      value={{ width, height, enter, tnCAccept, navbarActiveVar }}
    >
      <Helmet>
        <title>Hvll Digital</title>
        <meta
          name="description"
          content="Hvll Digital, creative web development agent based in London that crafts beautiful and original websites for brands who refuse to blend in."
        ></meta>
        <link rel="canonical" href="/"></link>
      </Helmet>

      <div className="primaryClrBGLight masterAppDiv">
        <TNCBar />

        {/* navbar */}
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/CreativeWebDesign" element={<CreativeWebDesign />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/TermsAndCondition" element={<TermsNCondition />} />
          <Route path="/EmailDevelopment" element={<EmailDevelopment />} />
          <Route path="/WebDevelopment" element={<WebDevelopment />} />
          <Route
            path="/WordPressDevelopment"
            element={<WordpressDevelopment />}
          />
        </Routes>

        {/* end of navbar */}
      </div>
    </ContextState.Provider>
  );
}

export default App;
