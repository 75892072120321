import style from "./creativeWebDesign.module.css";
import { ContextState } from "../../App";
import { useContext } from "react";
import BackButtonContainer from "../component/backButton/backButtonContainer";
import HeroSectionTemplate from "../component/HeroSectionTemplate/heroSectionTemplate";
import StepsConsulting from "../component/stepsConsulting/stepsConsulting";
import FooterSection from "../../footer/footer";
import CaseStudyTemplate from "../component/caseStudyTemplate/caseStudyTemplate";
import {Helmet} from "react-helmet-async";

function CreativeWebDesign() {
  const { width, height } = useContext(ContextState);

  return (
    <section
      className={style.CreativeWebDesign}
      style={{ maxWidth: `${width}px`, minHeight: `${height}px` }}
    >
      <Helmet>
        <title>Creative Web Design</title>
        <meta name="description" content="Hire Us to create unique one of the kind Web Design"></meta>
        <link rel="canonical" href="/CreativeWebDesign"></link>
      </Helmet>
      {/* this is the back button container */}
      <BackButtonContainer />
      <HeroSectionTemplate
        heading="Creative Web Design"
        subHeading={
          "Elevate your brand's online presence with a customized website that enhances engagement and generates a tangible return on creative investment."
        }
        textCopyWriting={
          "Before we proceed with visuals, we will meticulously analyzes the goals, strategy, and site structure. This ensures a flawless user experience and meets success markers."
        }
      />
      <StepsConsulting
        steps={[
          "Stakeholder kick-off meeting",
          "Discovery Document",
          "Finalise the Sitemap",
          "Wireframing & Prototyping(UX)",
          "High Fidelity Designs(UI)",
        ]}
      />
      {/* <div className={` h3 fontBlack fontClrPrimary`} style={{ width: "90%" }}>
        <p></p>
      </div> */}

      <CaseStudyTemplate
        IndividualCaseStudyHeading={""}
        moodBoard={"https://i.ibb.co/WP4T5zj/Group-121-1.png"}
        landingImg={"https://i.ibb.co/1qTwFzT/Group-107-1.png"}
        hiLowImg={"https://i.ibb.co/pwsvHYc/Group-134-1.png"}
        individualCaseStudyexplanation={
          "Transform the current website's UI and UX into a sleek, professional, and up-to-date masterpiece that showcases the brand in a prestigious manner."
        }
      />

      <FooterSection />
    </section>
  );
}

export default CreativeWebDesign;
