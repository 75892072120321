import { ContextState } from "../../../App";
import style from "./backButtonContainer.module.css"
import { useContext,useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faLessThan,
} from "@fortawesome/free-solid-svg-icons";

function BackButtonContainer() {
     const [isEnterBackContainer, setIsEnterBackContainer] = useState(false);

    const { width} = useContext(ContextState);
  return (
    <section
      className={style.backButtonMainContainer}
      style={{ width: `${width * 0.95}px` }}
    >
      <Link
        className={`${style.linkContainer} fontPrimaryClr `}
        to={`/`}
        style={{ textDecoration: "none" }}
        onMouseEnter={() => setIsEnterBackContainer(true)}
        onMouseLeave={() => setIsEnterBackContainer(false)}
      >
        <div className={style.arrowContainer}>
          <FontAwesomeIcon
            icon={faLessThan}
            className={`${
              isEnterBackContainer
                ? `fontSecondaryClr ${style.transitionBackButtonTemplate}`
                : `fontPrimaryClr ${style.transitionBackButtonTemplate}`
            } ${style.arrowLessThan}`}
          />
          <div
            className={`${style.arrowBody}  ${
              isEnterBackContainer
                ? `secondaryClr ${style.transitionBackButtonTemplate}`
                : `primaryClrBGDark ${style.transitionBackButtonTemplate}`
            }`}
          ></div>
        </div>

        <div
          className={`${style.wordBackContainer} fontBlack h3 ${
            isEnterBackContainer
              ? `fontSecondaryClr ${style.transitionBackButtonTemplate}`
              : `fontPrimaryClr ${style.transitionBackButtonTemplate}`
          }`}
        >
          Back
        </div>
      </Link>
    </section>
  );
}

export default BackButtonContainer;
