import { ContextState } from "../../../App";
import style from "./heroSectionTemplate.module.css";
import { useContext } from "react";

function HeroSectionTemplate({ heading, subHeading, textCopyWriting }) {
  const { width } = useContext(ContextState);

  return (
    <section
      className={style.heroSectionTemplateMainContainer}
      style={{ width: `${width * 0.95}px` }}
    >
      <div
        className={`${style.heroHeadingContainer} h3 fontBlack fontClrPrimary`}
      >
        {heading}
      </div>

      <div className={`${style.heroSubHeadingContainer}`}>
        <div className={`${style.heroSubHeading} fontBlack h5 fontClrBlack`}>
          {subHeading}
        </div>
      </div>

      <div className={`${style.textCopyWritingContainer}`}>
        <div
          className={`${style.textCopyWritingContent} h5 fontBlack fontClrBlack`}
        >
          {textCopyWriting}
        </div>
      </div>
    </section>
  );
}

export default HeroSectionTemplate;
