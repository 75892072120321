import style from "./heroSection.module.css";
import { ContextState } from "../App";
import {useContext} from "react";
import { motion, AnimatePresence } from "framer-motion";

function HeroSection() {

    const {width} = useContext(ContextState); 

  return (
    <section
      className={style.heroSection}
      style={{ width: `${width * 0.9}px` }}
    >
      <AnimatePresence>
        <motion.div
          className={`${style.heroLeftContainer} fontBlack h3 fontPrimaryClr`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 3 }}
        >
          <div className={`${style.heroLeftContainerPara}`}>
          Your Global <br></br> Web-Development<br></br> Agent
          </div>
        </motion.div>

        <motion.div
          className={`${style.heroRightContainer} fontRegular h5 fontPrimaryClr`}
          initial={{ x: 400 }}
          animate={{x:0}}
          transition={{ duration: 1 }}
        >
          <div className={`${style.heroRightContainerPara}`}>
            Creative web development agent based in London that crafts beautiful
            and original websites for brands who refuse to blend in.
          </div>
        </motion.div>
      </AnimatePresence>
    </section>
  );
}

export default HeroSection;
