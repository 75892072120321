import FooterSection from "../../footer/footer";
import BackButtonContainer from "../../services/component/backButton/backButtonContainer";
import { ContextState } from "../../App";
import { useContext } from "react";
import style from "./legal.module.css";

function LegalTemplate({legalHeading, content}) {
  const { width, height } = useContext(ContextState);

  return (
    <section
      className={style.CreativeWebDesign}
      style={{ maxWidth: `${width}px`, minHeight: `${height}px` }}
    >
      <BackButtonContainer />
      <div className={`h3 fontBlack fontClrPrimary `}>
        <p>{legalHeading}</p>
      </div>
      <div
        className={`subP fontRegular fontClrBlack `}
        style={{ width: "90%" }}
      >
        <p style={{ textAlign: "left" }}>{content}</p>
      </div>

      <FooterSection />
    </section>
  );
}

export default LegalTemplate;
