import style from "./aboutMeSection.module.css";
import { ContextState } from "../App";
import { useContext} from "react";
import { motion, AnimatePresence } from "framer-motion";

function AboutMeSection() {
  const { width } = useContext(ContextState);

  return (
    <section
      className={style.aboutMeSection}
      style={{ width: `${width * 0.9}px` }}
    >
      <div
        className={`${style.aboutMeTopContainer} fontRegular h3 fontPrimaryClr`}
      >
        <AnimatePresence>
          <motion.div
            className={`${style.aboutMeTopContainerPictureHolder} h5`}
          >
            <img
              src={"https://i.ibb.co/ZNrGpvt/Group-132-2.png"}
              alt="About Me"
            ></img>
          </motion.div>
        </AnimatePresence>
      </div>

      <div
        className={`${style.aboutMeBottomContainer} fontRegular h5 fontPrimaryClr`}
      >
        <div className={`${style.aboutMeBottomContainerPara}`}>
          Transforming dreams into reality is a passion of ours. With a diverse
          skill set encompassing UX/UI, front-end development, and Custom
          WordPress expertise, we collectively craft remarkable user
          experiences.
        </div>
      </div>
    </section>
  );
}

export default AboutMeSection;
