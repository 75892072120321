import style from "./footer.module.css";
import { ContextState } from "../App";
import { useContext} from "react";
import { Link } from "react-router-dom";

function FooterSection() {
  const { width } = useContext(ContextState);

  return (
    <section
      className={style.footerSection}
      style={{ width: `${width * 0.95}px` }}
    >
      <div
        className={`${style.footerTopContainer} fontRegular  fontPrimaryClr`}
      >
        <div
          className={`${style.footerTopLeftContainer} fontRegular fontPrimaryClr`}
        >
          <ul className={`${style.footerContainerListContainer}`}>
            <li>
              <Link
                to={`/Privacypolicy`}
                className={`${style.footerContainerListContainerLink} fontRegular  fontPrimaryClr subP`}
                style={{ textDecoration: "none" }}
              >
                Privacy policy
              </Link>
            </li>

            <li>
              <Link
                to={`/TermsAndCondition`}
                className={` ${style.footerContainerListContainerLink} fontRegular subP fontPrimaryClr`}
                style={{ textDecoration: "none" }}
              >
                Terms and Conditions
              </Link>
            </li>

            <li
              className={`${style.footerContainerListContainerLink} fontRegular subP fontPrimaryClr`}
            >
              <a
                href="mailto:info@hvlldigital.com"
                className={"fontPrimaryClr fontRegular subP"}
                style={{ textDecoration: "none" }}
              >
                info@hvlldigital.com
              </a>
            </li>
          </ul>
        </div>
      </div>  
    </section>
  );
}

export default FooterSection;
