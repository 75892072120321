import LegalTemplate from "../template/legal";
import { Helmet } from "react-helmet-async";

function PrivacyPolicy(){
    return (
      <div>
        <Helmet>
          <title>Privacy Policy</title>
          <meta name="description" content="Hvll Digital Privacy Policy"></meta>
          <link rel="canonical" href="/PrivacyPolicy"></link>
        </Helmet>

        <LegalTemplate
          legalHeading={"Privacy Policy"}
          content={`

          At Hvll Digital, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect the information you provide when using our website. By using our website, you consent to the practices described in this Privacy Policy.
          \n
          Information We Collect
          1.1 Personal Information: We may collect personal information that you voluntarily provide to us when contacting us or using our services. This may include your name, email address, contact information, and any other information you choose to provide.

          1.2 Usage Information: We may automatically collect certain information when you visit our website, such as your IP address, browser type, operating system, referring URLs, and the pages you access on our website. This information helps us analyze trends, administer the site, track user movements, and gather demographic information.

          Use of Collected Information
          2.1 Personal Information: We may use the personal information you provide to respond to your inquiries, provide the services you request, personalize your experience on our website, and send you relevant information about our products or services. We may also use your personal information for internal purposes such as data analysis, research, and improving our website and services.

          2.2 Usage Information: We may use usage information to monitor and analyze the use of our website, identify technical issues, and improve the overall user experience. This information is generally collected in an aggregated and anonymous form.

          Disclosure of Information
          3.1 Third-Party Service Providers: We may share your personal information with trusted third-party service providers who assist us in operating our website, conducting our business, or providing services to you. These service providers are obligated to keep your information confidential and are only authorized to use your information as necessary to provide the requested services.

          3.2 Legal Requirements: We may disclose your personal information if required by law, court order, or governmental regulation, or if we believe in good faith that such disclosure is necessary to protect our rights, prevent fraud, enforce our terms and conditions, or respond to a legal request.

          Data Security
          We implement appropriate technical and organizational measures to protect the security of your personal information and prevent unauthorized access, disclosure, alteration, or destruction. However, please note that no method of transmission over the internet or electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee absolute security.

          Third-Party Websites
          Our website may contain links to third-party websites or services that are not owned or controlled by us. This Privacy Policy applies only to our website, and we are not responsible for the privacy practices of any third-party websites. We encourage you to review the privacy policies of those websites before providing any personal information.

          Children's Privacy
          Our website is not intended for children under the age of 13. We do not knowingly collect personal information from children under the age of 13. If we become aware that we have collected personal information from a child under the age of 13, we will take reasonable steps to delete that information from our records.

          Your Rights
          You have the right to access, update, and correct your personal information held by us. You may also have the right to request the deletion of your personal information or restrict its processing. If you wish to exercise any of these rights or have any questions or concerns regarding your personal information, please contact us using the information provided below.

          Changes to the Privacy Policy
          We reserve the right to update or modify this Privacy Policy at any time without prior notice. Any changes will be effective immediately upon posting the revised Privacy Policy on our website. We encourage you to review this Privacy Policy periodically to stay informed`}
        />
      </div>
    );
    }

export default PrivacyPolicy;