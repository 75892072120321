import FooterSection from "../../footer/footer";
import BackButtonContainer from "../component/backButton/backButtonContainer";
import CaseStudyTemplate from "../component/caseStudyTemplate/caseStudyTemplate";
import HeroSectionTemplate from "../component/HeroSectionTemplate/heroSectionTemplate";
import StepsConsulting from "../component/stepsConsulting/stepsConsulting";
import style from "./webDevelopment.module.css";
import { ContextState } from "../../App";
import { useContext } from "react";
import { Helmet } from "react-helmet-async";

function WebDevelopment() {
  const { width, height } = useContext(ContextState);

  return (
    <section
      className={style.webDevelopment}
      style={{ maxWidth: `${width}px`, minHeight: `${height}px` }}
    >
      <Helmet>
        <title>Web Development</title>
        <meta
          name="description"
          content="Hire Us to build Your web application"
        ></meta>
        <link rel="canonical" href="/WebDevelopment"></link>
      </Helmet>

      {/* this is the back button container */}
      <BackButtonContainer />
      <HeroSectionTemplate
        heading="Web Development"
        subHeading={
          "Unleash your brand's potential online with a tailor-made website that amplifies engagement and delivers creative dividends."
        }
        textCopyWriting={
          "Before we delve into the visuals, we will thoroughly evaluates site goals, strategy, and architecture. This meticulous process guarantees a flawless user experience and ensures the achievement of success indicators."
        }
      />
      <StepsConsulting
        steps={[
          "Stakeholder kick-off meeting",
          "Discovery Document",
          "Finalise the Sitemap",
          "Wireframing & Prototyping(UX)",
          "High Fidelity Designs(UI)",
          "Front-End Development",
        ]}
      />

      {/* <div className={` h3 fontBlack fontClrPrimary`} style={{ width: "90%" }}>
        <p>Case Study</p>
      </div> */}

      <CaseStudyTemplate
        IndividualCaseStudyHeading={""}
        moodBoard={"https://i.ibb.co/5B8gVYr/fitnitiative-Mood-1.png"}
        landingImg={"https://i.ibb.co/rsGKCRc/Group-128-1.png"}
        hiLowImg={"https://i.ibb.co/GtGwxyB/Group-136-1.png"}
        individualCaseStudyexplanation={
          "Revamp website for improved user experience. Prioritize navigation, captivate with design. Enhance presentation, sitemap, sections, condense info."
        }
      />

      <FooterSection />
    </section>
  );
}

export default WebDevelopment;
