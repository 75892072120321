import { ContextState } from "../../../App";
import style from "./caseStudyTemplate.module.css";
import { useContext } from "react";


function CaseStudyTemplate({
  IndividualCaseStudyHeading,
  individualCaseStudyexplanation,
  moodBoard,
  landingImg,
  hiLowImg,
}) {
  const { width } = useContext(ContextState);

  return (
    <section
      className={style.caseStudyTemplateMainContainer}
      style={{ width: `${width * 0.9}px` }}
    >
    

      <div className={`${style.caseStudyTemplateIndividualContainer} `}>
        <div
          className={`${style.caseStudyTemplateIndividualHeadingContainer} h2 fontClrPrimary fontBlack`}
        >
          {IndividualCaseStudyHeading}
        </div>
        <div className={`${style.caseStudyTemplateIndividualTopContainer}`}>
          <div
            className={`${style.caseStudyTemplateIndividualTopLeftContainer}`}
          >
            <img src={landingImg} alt="Dining Expert Landing"></img>
          </div>

          <div
            className={`${style.caseStudyTemplateIndividualTopRightContainer}`}
          >
            <div
              className={`${style.caseStudyTemplateIndividualTopMoodContainer}`}
            >
              <div className={`${style.caseStudyTemplateIndividualTopMood}`}>
                <img src={moodBoard} alt="Dining Expert Moodboard"></img>
              </div>
            </div>
            <div
              className={`${style.caseStudyTemplateIndividualTopExplContainer} fontBlack`}
            >
              <div
                className={`${style.caseStudyTemplateIndividualTopExpl} fontBlack fontClrPrimary h5`}
              >
                <p>{individualCaseStudyexplanation}</p>
              </div>
            </div>
          </div>
        </div>
        {/* low fi and high fi */}
        <div className={`${style.caseStudyTemplateIndividualMiddleContainer}`}>
          <img src={hiLowImg} alt="Dining Expert HI Low Fidelity"></img>
        </div>

      </div>
    </section>
  );
}

export default CaseStudyTemplate;
