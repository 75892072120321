import {useContext} from "react";
import { ContextState } from "../App";

function TNCBar() {
  const { enter, tnCAccept, navbarActiveVar } = useContext(ContextState);

  return (
    <div
      className={`homeTermsNConditionContainer TertiaryClr ${
        tnCAccept[0] ? null : "tNCAccepted"
      }`}
      style={{
        border: navbarActiveVar[0]
          ? `1px solid transparent`
          : `1px solid #FF9601`,
      }}
    >
      <p className="subP fontClrBlack ">
        By continuing You agreed with the terms and condition and privacy policy
        of this website
      </p>
      <button
        onMouseEnter={() => enter[1](false)}
        onMouseLeave={() => enter[1](true)}
        onClick={() => tnCAccept[1](!tnCAccept)}
        className={`${
          enter[0]
            ? "primaryClr transitionTnC fontClrWhite"
            : "fontSecondaryClr transitionTnC primaryClrBGLight"
        } subP `}
      >
        Close
      </button>
    </div>
  );
}

export default TNCBar;
