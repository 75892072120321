import { ContextState } from "../../../App";
import style from "./stepsConsulting.module.css";
import { useContext} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

function StepsConsulting({steps}) {

  const { width } = useContext(ContextState);

  const individualSteps=steps
  console.log(steps)
  return (
    <section
      className={style.StepsConsultingMainContainer}
      style={{ width: `${width * 0.9}px` }}
    >
      <div
        className={`${style.StepsConsultingHeadingContainer} h3 fontBlack fontPrimaryClr`}
      >
        <p>Process</p>
      </div>

      <div className={`${style.StepsConsultingStepsContainer} `}>
        <div
          className={`${style.StepsConsultingStepsListContainer} h4 fontClrBlack fontRegular`}
        >
          <ul>
            {individualSteps.map((input) => {
              if (
                individualSteps.indexOf(input) !==
                individualSteps.length - 1
              ) {
                return (
                  <>
                    <li>{input}</li>
                    <li>
                      <FontAwesomeIcon icon={faArrowDown}></FontAwesomeIcon>
                    </li>
                  </>
                );
              } else {
                return (
                  <>
                    <li>{input}</li>
                  </>
                );
              }
            })}
           
          </ul>
        </div>
      </div>
    </section>
  );
}

export default StepsConsulting;
