import style from "./emailDevelopment.module.css";
import { ContextState } from "../../App";
import { useContext } from "react";
import BackButtonContainer from "../component/backButton/backButtonContainer";
import CaseStudyTemplate from "../component/caseStudyTemplate/caseStudyTemplate";
import FooterSection from "../../footer/footer";
import HeroSectionTemplate from "../component/HeroSectionTemplate/heroSectionTemplate";
import StepsConsulting from "../component/stepsConsulting/stepsConsulting";
import { Helmet } from "react-helmet-async";

function EmailDevelopment() {
  const { width, height } = useContext(ContextState);

  return (
    <section
      className={style.emailDevelopment}
      style={{ maxWidth: `${width}px`, minHeight: `${height}px` }}
    >
      <Helmet>
        <title>Email Development</title>
        <meta
          name="description"
          content="Hire Us to create unique one of the kind Web HTML Email"
        ></meta>
        <link rel="canonical" href="/EmailDevelopment"></link>
      </Helmet>
      {/* this is the back button container */}
      <BackButtonContainer />

      <HeroSectionTemplate
        heading="Email Development"
        subHeading={
          "Bring your brand to life through captivating HTML email development that amplifies engagement and generates creative returns."
        }
        textCopyWriting={
          "Unlock the true potential of HTML custom email. Our team goes beyond aesthetics, delving into site goals, strategy, and architecture to ensure a seamless user experience that surpasses expectations."
        }
      />
      <StepsConsulting
        steps={[
          "Stakeholder kick-off meeting",
          "Discovery Document",
          "Finalise the Email layout",
          "High Fidelity Designs(UI)",
          "HTML Email Development",
        ]}
      />

      <div className={` h3 fontBlack fontClrPrimary`} style={{ width: "90%" }}>
        {/* <p>Case Study</p> */}
      </div>
      <CaseStudyTemplate
        IndividualCaseStudyHeading={""}
        moodBoard={"https://i.ibb.co/Jtc5djc/Group-127-1.png"}
        landingImg={"https://i.ibb.co/ydYHWV1/Group-126-1.png"}
        hiLowImg={"https://i.ibb.co/JpDZ5SP/Group-135-2.png"}
        individualCaseStudyexplanation={
          "Design and construct a custom HTML email meticulously tested across multiple providers. The development also includes implementing both dark mode and light mode for a visually stunning experience."
        }
      />

      <FooterSection />
    </section>
  );
}

export default EmailDevelopment;
