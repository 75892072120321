import style from "./servicesSection.module.css";
import { ContextState } from "../App";
import { useContext} from "react";
import { Link } from "react-router-dom";

function ServiceSection() {
  const { width } = useContext(ContextState);

  return (
    <section
      className={style.ServiceSection}
      style={{ width: `${width * 0.9}px` }}
    >
      <div
        className={`${style.serviceTopContainer} fontBlack h1 fontPrimaryClr`}
      >
        <div className={`${style.serviceTopContainerFont}`}>Services</div>
      </div>

      <div
        className={`${style.serviceBottomContainer} fontRegular h5 fontPrimaryClr`}
      >
        <ul className={`${style.serviceBottomContainerList}`}>
          <li>
            <Link
              to={`/CreativeWebDesign`}
              className={`${style.serviceBottomContainerListLink} fontRegular h4 fontPrimaryClr`}
              style={{ textDecoration: "none" }}
              onClick={() => {
                window.scroll(0, 0);
              }}
            >
              Creative Web Design
            </Link>
          </li>

          <li>
            <Link
              to={`/WebDevelopment`}
              className={`${style.serviceBottomContainerListLink} fontRegular h4 fontPrimaryClr`}
              style={{ textDecoration: "none" }}
              onClick={() => {
                window.scroll(0, 0);
              }}
            >
              Web Development
            </Link>
          </li>

          <li>
            <Link
              to={`/WordPressDevelopment`}
              className={`${style.serviceBottomContainerListLink} fontRegular h4 fontPrimaryClr`}
              style={{ textDecoration: "none" }}
              onClick={() => {
                window.scroll(0, 0);
              }}
            >
              Wordpress Development
            </Link>
          </li>

          <li>
            <Link
              to={`/EmailDevelopment`}
              className={`${style.serviceBottomContainerListLink} fontRegular h4 fontPrimaryClr`}
              style={{ textDecoration: "none" }}
              onClick={() => {
                window.scroll(0, 0);
              }}
            >
              Email Development
            </Link>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default ServiceSection;
