import { ContextState } from "../../App";
import { useContext } from "react";
import BackButtonContainer from "../component/backButton/backButtonContainer";
import HeroSectionTemplate from "../component/HeroSectionTemplate/heroSectionTemplate";
import StepsConsulting from "../component/stepsConsulting/stepsConsulting";
import CaseStudyTemplate from "../component/caseStudyTemplate/caseStudyTemplate";
import FooterSection from "../../footer/footer";
import style from "./wordPressDevelopment.module.css";
import { Helmet } from "react-helmet-async";

function WordpressDevelopment() {
  const { width, height } = useContext(ContextState);

  return (
    <section
      className={style.wordPressDevelopment}
      style={{ maxWidth: `${width}px`, minHeight: `${height}px` }}
    >
      <Helmet>
        <title>WordPress Development</title>
        <meta
          name="description"
          content="Hire Us to build Your web application using WordPress"
        ></meta>
        <link rel="canonical" href="/WordPressDevelopment"></link>
      </Helmet>

      {/* this is the back button container */}

      <BackButtonContainer />
      <HeroSectionTemplate
        heading="WordPress Development"
        subHeading={
          "Elevate your brand's online presence with a customized WordPress website that enhances engagement and maximizes creative potential."
        }
        textCopyWriting={
          "Crafting WordPress websites goes beyond visuals. Our team delves into site goals, strategy, and architecture, ensuring a flawless user experience. "
        }
      />

      <StepsConsulting
        steps={[
          "Stakeholder kick-off meeting",
          "Discovery Document",
          "Finalise the Sitemap",
          "Wireframing & Prototyping(UX)",
          "High Fidelity Designs(UI)",
          "Front-End Development",
          "WordPress Development",
        ]}
      />

      <div className={` h3 fontBlack fontClrPrimary`} style={{ width: "90%" }}>
        {/* <p>Case Study</p> */}
      </div>

      <CaseStudyTemplate
        IndividualCaseStudyHeading={""}
        moodBoard={"https://i.ibb.co/MpZWmS0/Group-125-2.png"}
        landingImg={"https://i.ibb.co/6nzS4rJ/Group-123-1.png"}
        hiLowImg={"https://i.ibb.co/9TxYz80/Group-133-1.png"}
        individualCaseStudyexplanation={
          "Transfering the legacy website to WordPress and taking advantage of the WordPress CMS for easy use for all. "
        }
      />

      <FooterSection />
    </section>
  );
}

export default WordpressDevelopment;
