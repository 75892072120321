import LegalTemplate from "../template/legal";
import { Helmet } from "react-helmet-async";

function TermsNCondition() {
  return (
    <div>
      <Helmet>
        <title>Terms and Condition</title>
        <meta
          name="description"
          content="Hvll Digital Terms and Condition"
        ></meta>
        <link rel="canonical" href="/TermsAndCondition"></link>
      </Helmet>

      <LegalTemplate
        legalHeading={"Terms and Conditions"}
        content={`Please read these Terms and Conditions ("Agreement") carefully before using Hvll Digital (referred to as "we," "us," or "our"). By accessing or using our website, you agree to be bound by these Terms and Conditions. If you do not agree with any part of these Terms and Conditions, you may not access or use the website.
        \n
        User Responsibilities
        1.1 Age Requirement: By accessing or using Hvll Digital, you represent and warrant that you are at least 18 years old or have obtained parental consent to use the website.

        1.2 Accurate Information: You agree to provide accurate, current, and complete information when using Hvll Digital and to update such information if necessary.

        1.3 Prohibited Activities: When using Hvll Digital, you agree not to engage in any of the following prohibited activities:

        Violating any applicable laws or regulations.
        Infringing upon the intellectual property rights of others.
        Uploading, posting, or transmitting any content that is unlawful, harmful, defamatory, obscene, or otherwise objectionable.
        Interfering with or disrupting the operation of Hvll Digital or the servers or networks connected to Hvll Digital.
        Attempting to gain unauthorized access to any portion of Hvll Digital or any other systems or networks.
        Limitation of Liability
        2.1 Disclaimer: Hvll Digital is provided on an "as is" and "as available" basis. We make no representations or warranties of any kind, express or implied, regarding the operation or availability of Hvll Digital, the accuracy, completeness, or reliability of any information contained on Hvll Digital, or the suitability of Hvll Digital for any purpose.

        2.2 Limitation of Liability: To the maximum extent permitted by law, Hvll Digital shall not be liable for any direct, indirect, incidental, consequential, or special damages arising out of or in any way related to your use of Hvll Digital, even if advised of the possibility of such damages.

        Links to Third-Party Websites
        Hvll Digital may contain links to third-party websites or services that are not owned or controlled by Hvll Digital. We do not endorse or assume any responsibility for the content, privacy practices, or actions of any third-party websites. You acknowledge and agree that Hvll Digital shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such websites.

        Changes to the Terms and Conditions
        We reserve the right to update or modify these Terms and Conditions at any time without prior notice. Any changes will be effective immediately upon posting the revised Terms and Conditions on Hvll Digital. By continuing to access or use Hvll Digital after any modifications, you agree to be bound by the updated Terms and Conditions.

        Governing Law
        These Terms and Conditions shall be governed by and construed in accordance with the laws of [Jurisdiction]. Any dispute arising out of or in connection with these Terms and Conditions shall be subject to the exclusive jurisdiction of the courts of [Jurisdiction].

        By using Hvll Digital, you acknowledge that you have read and understood these Terms and Conditions and agree to abide by them.`}
      />
    </div>
  );
}

export default TermsNCondition;
